<template>
  <div id="Login" class="Login">
    <a @click="$router.go(-1)">
      <img
        :src="icons.white.backArrow"
        alt="go back"
        class="Login__back-btn d-md-none"
      />
    </a>
    <v-container
      class="container d-flex flex-column justify-center flex-md-row mx-auto"
    >
      <router-link to="/" class="Login__logo mx-auto my-auto">
        <img :src="logos.hummingbird.main" alt="Logo HummingBird"/>
      </router-link>

      <form
        class="Login__form d-flex flex-column justify-center pa-md-10 mx-auto"
        novalidate
        @submit.prevent="iniciarSesionConEmailYClave"
      >
        <h1 class="text-center text-md-left">{{ lenguages[idioma].login.iniciaSesion }}</h1>
        <label for="LoginEmail">{{ lenguages[idioma].login.correoElectronico }}</label>
        <input
          solo
          type="email"
          id="LoginEmail"
          name="email"
          autofocus
          v-model="email"
        />

        <label for="LoginPassword">{{ lenguages[idioma].login.contrasena }}</label>
        <input solo id="LoginPassword" type="password" v-model="password" />

        <a href="" class="Login__form__forgot text-center d-none"
          >OLVIDÉ MI CONTRASEÑA</a
        >

        <v-btn
          class="Login__form__loginBtn"
          :color="colors.mainPurple"
          dark
          type="submit"
          >{{ lenguages[idioma].login.iniciarSesion }}</v-btn
        >

        <router-link :to="{name: 'Registrate'}" class="Login__form__signUp text-center text-md-left"
          >{{ lenguages[idioma].login.noTienesCuenta }} <span>{{ lenguages[idioma].login.registrate }}</span></router-link
        >

        <v-btn
          class="Login__form__fbBtn rounded-xl py-3"
          color="#2582D8"
          dark
          @click="iniciarConFacebook"
        >
          <img :src="logos.facebook" />
          <span>{{ lenguages[idioma].login.continuarConFacebook }}</span>
        </v-btn>

        <v-btn
          class="Login__form__igBtn rounded-xl py-3"
          color="red"
          dark
          @click="iniciarConGoogle"
        >
          <v-icon>mdi-google</v-icon>
          <span>{{ lenguages[idioma].login.continuarConGoogle }}</span>
        </v-btn>
      </form>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
    };
  },
  computed: {
    ...mapState(["colors", "logos", "icons"]),
    ...mapState("Lenguages", ["lenguages", "idioma"]),
  },
  methods: {
    ...mapActions("Auth", [
      "signInWithEmailAndPass",
      "signInWithFb",
      "signInWithGoogle",
    ]),
    async iniciarSesionConEmailYClave() {
      const usuario = {
        email: this.email,
        password: this.password,
      };

      let iniciarSesion = await this.signInWithEmailAndPass(usuario);
      iniciarSesion ? this.$router.push("/") : false;
    },
    async iniciarConFacebook() {
      let iniciarSesion = await this.signInWithFb();
      iniciarSesion ? this.$router.push("/") : false;
    },
    async iniciarConGoogle() {
      let iniciarSesion = await this.signInWithGoogle();
      iniciarSesion ? this.$router.push("/") : false;
    },
  },
  title() {
    return `Inicia sesión - HummingBird`;
  },
};
</script>

<style lang="scss" scoped>
#Login {
  @include bgimgCover(
    "https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/assets%2Fpasajero%2Fimagen%202login%20bg%20mobile.png?alt=media&token=4a358437-1ec1-4e68-9c6a-eb4ff3533007"
  );
  // background-attachment: fixed;
  color: $main-white;
  min-height: 100vh;
  font-size: 13px;
  .Login {
    &__back-btn {
      padding: 60px 0 0 21px;
    }
    &__logo {
      padding: 40px 0 23px 0;
      img {
        width: 204px;
      }
    }
    &__form {
      padding-top: 32px;
      width: 78%;
      &__forgot {
        padding: 28px 0;
        font-size: 13px;
      }
      &__loginBtn {
        margin: 0 0 22px 0;
        height: 55px;
        font-weight: 700;
      }
      &__signUp {
        padding-bottom: 18px;
        font-size: 13px;
        span {
          color: $main-purple;
        }
      }
      &__fbBtn,
      &__igBtn {
        padding: 4rem 16px;
        text-transform: initial;
        font-size: 13px;
        font-weight: 700;
        height: 55px;
        img {
          height: 24px;
        }
        span {
          padding-left: 10px;
        }
      }
      &__fbBtn {
        margin-bottom: 18px;
      }
      &__igBtn {
        margin-bottom: 41px;
      }
    }
  }

  h1 {
    font-weight: 400;
    font-size: 23px;
    padding-bottom: 32px;
  }
  a {
    font-size: 0.8125em;
    font-weight: 700;
  }

  label {
    padding-bottom: 10px;
    font-weight: 700;
  }

  #LoginEmail,
  #LoginPassword,
  label {
    display: block;
  }
  #LoginEmail,
  #LoginPassword {
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 10px;
    height: 55px;
    padding: 0 2%;
  }

  #LoginEmail {
    margin-bottom: 1rem;
  }
}

@media (min-width: 960px) {
  #Login {
    @include bgimgCover(
      "https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/assets%2Fpasajero%2Fimagen%202%20desktop%20login%20bg.png?alt=media&token=affab533-1ee5-4883-be71-8958501ce4d1"
    );
    transition: background-image 1s ease;
    padding: 5% 0;
    .Login {
      &__form {
        background-color: $main-white;
        border-radius: 10px;
        color: $main-black;
        transition: all 1s ease;
        width: 450px;
        h1 {
          font-size: 35px;
          font-weight: 700;
          order: -2;
          padding: 0;
        }
        #LoginEmail,
        #LoginPassword {
          border: 1px solid #aeaeae;
          // width: 371px;
        }
        &__signUp {
          order: -1;
        }
        &__loginBtn {
          margin: 32px 0 22px 0;
        }
        &__igBtn {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>